import React from 'react';
import Share from './Share';
import DLink from './DLink';

const Cat = (p) => {
   return (
      <li className="trends__menu-item">
         <DLink to={'/trends/' + p.prp.slug} className="trends__menu-a">{p.prp.title}<span className={'trends__menu-arr ct-' + p.prp.color}></span></DLink>
         <span className={'js-anim-card trends__menu-sbstr ct-' + p.prp.color}><span className="js-anim-card__in trends__menu-sbstr-in" style={{ backgroundImage: 'url(' + p.prp.avatar + ')' }}></span></span>
      </li>
   );
}

const scrAnim = () => {
   window.$('.fadeInScreen, #header, #footer').animate({ opacity: 1 }, 500, function () {
      window.$('.trends__menu-a').css({ transform: 'translate(0px,0px)' });
   });

   window.CardAnimationInit();
}

class Categories extends React.Component {
   constructor(props) {
      super(props);

      this.props.getCats();
   }

   componentDidMount() {
      scrAnim();

      document.title = 'Тренды десятилетия';
      document.querySelector('meta[name="description"]').setAttribute('content', 'Каталог трендов десятилетия');
   }

   componentDidUpdate() {
      scrAnim();
   }

   render() {
      if (!this.props.cats) return null;

      return (
         <main className="main fadeInScreen fadeOutScreen">
            <div className="trends trends_all" style={{ minHeight: window.innerHeight + 'px' }}>

               <div className="block-quote trends__block-quote">
                  <div>
                     <div className="block-quote__tit">
                        Супермаркет —<br />
                        настоящий<br />
                        современный рай;<br />
                        житейская борьба<br />
                        прекращается<br />
                        у его дверей.
            </div>
                     <div className="block-quote__author">
                        Мишель Уэльбек
            </div>
                     <div className="block-quote__pos">
                        французский писатель, автор<br />
                        сборника эссе &laquo;Мир как супермаркет&raquo;
            </div>
                  </div>

                  <DLink to="/" className="link-btn block-quote__back-btn">в начало</DLink>

                  <button className="js-toggle link-btn block-quote__about-btn js-document-toggle-off" data-target-elements="#footer">о проекте</button>
               </div>

               <ul className="trends__menu">
                  {Object.keys(this.props.cats).map(k => (
                     <Cat
                        prp={this.props.cats[k]}
                        key={this.props.cats[k].id.toString()}
                     />
                  ))}
               </ul>

               <Share />

               <button className="js-toggle link-btn trends__about-btn js-document-toggle-off" data-target-elements="#footer">о проекте</button>
            </div>
         </main>
      );
   }
}

export default Categories;