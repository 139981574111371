import React from 'react';
import { Link, Route } from 'react-router-dom';

function goTo(prp, to) {
   window.$('.fadeOutScreen').animate({ opacity: 0 }, 200);

   window.$('.trends__menu-sbstr').css({ transition: '.5s', transform: 'translate(100%,0)' });
   window.$('.trends__menu-a').css({ transform: 'translate(-100%,0px)' });

   window.$('.trends__title, .desc-trend-units').css({ transform: 'translate(200%,0px)' });

   // var img = window.$('.unit__img img');
   // let h = img.height();
   // img.css('height', h + 'px').attr('src', 'https://tass.lucky-site.com/static/blank.png?v1');
   
   // setTimeout(function () {
   //    img.css('height', 'auto')
   // }, 1500);

   setTimeout(function () {
      window.$('.fadeOutScreenDelay').animate({ opacity: 0 }, 500);
   }, 500);

   setTimeout(() => {
      prp.history.push(to);
   }, 1000);
}

class DLink extends React.Component {
   render() {
      return (
         <Route render={(prp) => {
            return (
               <Link
                  onClick={(e) => {
                     e.preventDefault();
                     goTo(prp, this.props.to);
                  }}
                  {...this.props}
               />
            );
         }} />
      );
   }
}

export default DLink;