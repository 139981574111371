import React from 'react';
import Share from './Share';
import DLink from './DLink';
import pix from '../images/1.png';

const TrendMob2 = (p) => {
	return (
		<div className="trend-sl__item">
			<div className={'trend ' + 'ct-' + p.prp.color}>
				<div className="trend__img">
					<img src={pix} data-src={p.prp.avatar2} alt="img" className="lazy-img" />
				</div>
				<div className="trend__tit">
					{p.prp.title}
				</div>
				<div className="trend__side">
					тренд <span>{p.i + 1} / {p.sum}</span>
				</div>
				<DLink to={'/trend/' + p.prp.slug} className="trend__a"></DLink>
			</div>
		</div>
	);
}

const TrendDesk2 = (p) => {
	return (
		<div className="trend-sl-vert__item">
			<div className="d-trend">
				<div className="d-trend__tit">
					{p.prp.title}
				</div>
				<div className="d-trend__img">
					<img src={pix} data-src={p.prp.avatar1} alt="trend" className="lazy-img" />
				</div>
				<DLink to={'/trend/' + p.prp.slug} className="d-trend__a"></DLink>
				<div className="d-trend__img d-trend__img_hov">
					<img src={pix} data-src={p.prp.avatar5} alt="trend" className="lazy-img" />
				</div>
			</div>
		</div>
	);
}

let deskImgRef = React.createRef();
let mobImgRef = React.createRef();

const lazyLoad = function() {
	const imgEls = document.querySelectorAll('.lazy-img');

	for (let i = 0; i < imgEls.length; i++) {
		const iEl = imgEls[i];
		
		iEl.src = iEl.getAttribute('data-src');
	}
}

const onInit = function () {
	window.$('.fadeInScreen, #header').animate({ opacity: 1 }, 200);
	window.initSlider();
	window.initAudio();
	window.scrollTo(0, 0);

	const img = new Image();

	if (window.innerWidth > 1200) {
		const imgRef = deskImgRef.current;

		imgRef.innerHTML = '';

		imgRef.classList.add('unit__desk-img_loading');

		img.src = imgRef.getAttribute('data-src');

		img.addEventListener('load', function () {
			imgRef.innerHTML = '';
			imgRef.classList.remove('unit__desk-img_loading');
			imgRef.appendChild(img);
			lazyLoad();
		});
	} else {
		const imgRef = mobImgRef.current;

		imgRef.innerHTML = '';

		imgRef.classList.add('unit__img_loading');

		img.src = imgRef.getAttribute('data-src');

		img.addEventListener('load', function () {
			imgRef.innerHTML = '';
			imgRef.classList.remove('unit__img_loading');
			imgRef.appendChild(img);
			lazyLoad();
		});
	}

	setTimeout(lazyLoad, 7000);
}

class Trend extends React.Component {
	componentDidMount() {
		onInit();

		this.winLoc = window.location.pathname;
	}

	getSnapshotBeforeUpdate() {
		window.unmountSlider();
		window.initAudio(true);

		if (this.winLoc && this.winLoc !== window.location.pathname) {
			// window.$('.unit__img img, .unit__desk-img img').attr('src', '');
		}

		return null;
	}

	componentDidUpdate() {
		onInit();
		
		this.winLoc = window.location.pathname;
	}

	render() {
		if (!this.props.trends) return null;

		let trend = null,
			trendsObj = {},
			siblingTrendIds = [],
			next = null,
			prev = null,
			trands_count = 0,
			getBtnCl = '',
			linkToCat = '',
			catTitle = '';

		trend = this.props.trends[this.props.route.match.params.slug.replace(/-/g, '_')];

		for (const key in this.props.trends) {
			if (this.props.trends.hasOwnProperty(key)) {
				const trEl = this.props.trends[key];

				trendsObj[trEl.id] = trEl;

				if (trend && trend.cat_num === trEl.cat_num) {
					trands_count += 1
				}
			}
		}

		if (trend) {
			document.title = trend.title;
			document.querySelector('meta[name="description"]').setAttribute('content', trend.text.split('.')[0]);

			for (const key in trendsObj) {
				if (trendsObj.hasOwnProperty(key)) {
					const el = trendsObj[key];

					if (el.cat_num === trend.cat_num) {
						siblingTrendIds.push(el.slug);
					}
				}
			}

			if (siblingTrendIds.indexOf(trend.slug) < (siblingTrendIds.length - 1)) {
				next = siblingTrendIds[siblingTrendIds.indexOf(trend.slug) + 1];
			} else {
				next = siblingTrendIds[0];
			}

			if (siblingTrendIds.indexOf(trend.slug) > 0) {
				prev = siblingTrendIds[siblingTrendIds.indexOf(trend.slug) - 1];
			} else {
				prev = siblingTrendIds[siblingTrendIds.length - 1];
			}

			const cartIds = this.props.cart.map(el => el.id);

			if (cartIds.indexOf(trend.id) !== -1) {
				getBtnCl = ' get-btn-wrap_toggled';
			}

			if (this.props.cats) {
				for (const key in this.props.cats) {
					if (this.props.cats.hasOwnProperty(key)) {
						const cat = this.props.cats[key];

						if (cat.num === trend.cat_num) {
							linkToCat = '/trends/' + cat.slug;
							catTitle = cat.title;
						}
					}
				}
			}
		}

		let color = (trend) ? 'ct-' + trend.color : '';

		let mobTrendElems = (trend && window.innerWidth < 1200) ? trend.similar.map((p, i)=> <TrendMob2 prp={trendsObj[p]} i={i} sum={trend.similar.length} key={trendsObj[p].id} />) : '';

		let deskTrendElems = (trend && window.innerWidth > 1200) ? trend.similar.map(p => <TrendDesk2 prp={trendsObj[p]} key={trendsObj[p].id} />) : '';



		const addToCart = () => {
			this.tocart = true;

			this.props.addToCart(trend.id);

			window.$('.get-btn-wrap').addClass('get-btn-wrap_toggled');
		};

		return (
			<main className="main fadeInScreen fadeOutScreen">
				<div className={'unit ' + color}>
					<div className="row">
						<div className="col-12 p-0 lg-col-7 lg-col_offset-1">

							<div className="unit__top">
								<div className="unit__title">
									{(trend) ? trend.title : ''}
								</div>

								<div ref={mobImgRef} data-src={trend.avatar3} className="unit__img">
									{/* <img src={(trend) ? trend.avatar3 : ''} alt="img" /> */}
								</div>

								<div className={'get-btn-wrap' + getBtnCl}>
									<button className="get-btn" onClick={addToCart}>Взять в 2020</button>
									<div className="get-btn get-btn_active">готово</div>
								</div>
							</div>

							<div className="unit__txt-wrap">
								<div className="unit__txt lg-hidden">
									{(trend) ? trend.text : ''}
								</div>

								<div className="unit__txt md-x-hidden">
									{(trend) ? trend.text : ''}
								</div>

								<div className="pagenav">
									<div className="pagenav__num">
										{(trend) ? trend.num : ''} / {trands_count}
									</div>

									<DLink to={'/trend/' + prev} className="pagenav__prev"><svg width="24px" height="17px" viewBox="14.5 15.5 24 17"> <path className="arr-svg" d="M37,22.5H19.261l3.877-4.524c0.54-0.629,0.466-1.576-0.163-2.115c-0.628-0.539-1.576-0.467-2.115,0.163l-6,7 c-0.482,0.562-0.482,1.391,0,1.953l6,7C21.158,32.322,21.578,32.5,22,32.5c0.346,0,0.693-0.119,0.976-0.361 c0.629-0.539,0.702-1.486,0.163-2.115L19.261,25.5H37h1.5v-3H37z" /> </svg></DLink>

									<DLink to={'/trend/' + next} className="pagenav__next"><svg width="24px" height="17px" viewBox="14.5 15.5 24 17"> <path className="arr-svg" d="M16,25.5h17.738l-3.877,4.523c-0.54,0.629-0.467,1.576,0.162,2.115c0.628,0.539,1.576,0.467,2.115-0.163l6-7 c0.481-0.561,0.481-1.39,0-1.952l-6-7c-0.297-0.346-0.717-0.523-1.14-0.523c-0.346,0-0.692,0.119-0.976,0.361 c-0.629,0.539-0.702,1.486-0.162,2.115l3.877,4.523H16h-1.5v3H16z" /> </svg></DLink>
								</div>

								<div className="ta-c">
									<DLink to={linkToCat} className="unit__to-cat">вернуться к макротренду &laquo;{catTitle}&raquo;</DLink>
								</div>
							</div>

							<div className={'md-x-hidden get-btn-wrap' + getBtnCl}>
								<button className="get-btn unit__desk-btn" onClick={addToCart}>Взять в 2020</button>
								<div className="get-btn get-btn_active">готово</div>
							</div>

							{trend && trend.expert_file &&
								<div className={'player-wrap ' + color}>
									<div className="tit">подкаст</div>
									<div className="player-wrap__tit">
										<span className="bold">{(trend) ? trend.expert_filename : ''}</span> / {(trend) ? trend.expert_name : ''}
									</div>

									<div className="player-wrap__tit-desk">
										{(trend) ? trend.expert_filename : ''} <span>/ {(trend) ? trend.expert_name : ''}</span>
									</div>

									<div className="c-audio">
										<div className="c-audio__buttons">
											<button className="js-c-audio-bkw c-audio__bkw-btn">10</button>
											<button className="js-c-audio-play c-audio__play-btn"></button>
											<button className="js-c-audio-fwd c-audio__fwd-btn">10</button>
										</div>

										<div className="c-audio__bar"><span className="c-audio__track"></span></div>

										<div className="c-audio__dur">--:--</div>

										<audio id="c-audio" src={(trend) ? trend.expert_file : ''} className="c-audio__audio"></audio>
									</div>
								</div>}

						</div>

						<div className="col-4 md-x-hidden">
							<div ref={deskImgRef} data-src={trend.avatar4} className="unit__desk-img">
								{/* <img src={(trend) ? trend.avatar4 : ''} alt="img" /> */}
							</div>
						</div>
					</div>

					<div className="similar">
						<div className="tit">с этим выбирают</div>

						<div id="trend-slider" className="trend-sl">
							{mobTrendElems}
						</div>

						<div id="trend-slider-vertical" className="trend-sl-vert">
							<div className="trend-sl-vert__in">
								{deskTrendElems}
							</div>
						</div>

					</div>

					<Share />

				</div>
			</main>
		);
	}
}

export default Trend;