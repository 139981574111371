import React from 'react';

const Share = (pr) => {
   return (
      <div className={'sh-block' + ((pr.cl) ? ' ' + pr.cl : '')}>
         <button className="sh-btn js-toggle js-document-toggle-off" data-target-elements=".sh-block"></button>
         <ul className="sc">
            <li className="sc__item">
               <button className="f-btn js-share-btn" data-network="fb" data-share-url={window.location.origin}></button>
            </li>
            <li className="sc__item">
               <button className="t-btn js-share-btn" data-network="tw" data-share-url={window.location.origin} data-share-tit="Это десятилетие всё, несите следующее! — "></button>
            </li>
            <li className="sc__item">
               <button className="v-btn js-share-btn" data-network="vk" data-share-url={window.location.origin} data-share-img={window.location.origin + '/sh-vk.png'}></button>
            </li>
            <li className="sc__item">
               <button className="o-btn js-share-btn" data-network="ok" data-share-url={window.location.origin} data-share-img={window.location.origin + '/sh-ok.png'}></button>
            </li>
         </ul>
      </div>
   );
}

export default Share;