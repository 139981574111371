import React from 'react';
import DLink from './DLink';
import { NavLink } from 'react-router-dom';

class P404 extends React.Component {
   componentDidMount() {
      window.$('.fadeInScreen').animate({ opacity: 1 }, 500);
   }

   componentDidUpdate() {
      window.$('.fadeInScreen').animate({ opacity: 1 }, 500);
   }

   render() {
      return (
         <main className="main fadeInScreen fadeOutScreen">
            <div className="p-404">
               <div>
                  <div className="p-404__img"></div>
                  <div className="p-404__cont">
                     <div className="p-404__tit">
                        404
            </div>
                     <p>
                        Этот сервер не прошёл курс машинного обучения и не смог распознать ваш запрос.
            </p>
                     <p>
                        <DLink to="/trends" onClick={this.props.reRender}>Попробуйте еще раз</DLink>
                     </p>
                  </div>
               </div>
            </div>
         </main>
      );
   }
}

export default P404;