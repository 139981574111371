import React from 'react';
import logo from '../images/logo-sq.svg';
import DLink from './DLink';

const Footer = (pr) => {
   if (
      pr.route.location.pathname !== '/' &&
      pr.route.location.pathname !== '/trends'
   ) return null;

   return (
      <footer id="footer" className={'footer' + ((pr.cl) ? ' ' + pr.cl : '')}>

         {pr.route.location.pathname !== '/trends' &&
            <button className="js-tgl-off back-btn" data-target-elements="#footer"><span>в начало</span></button>
         }

         {pr.route.location.pathname === '/trends' &&
            <DLink to="/" history={pr.route.history} className="back-btn" data-target-elements="#footer"><span>в начало</span>
            </DLink>
         }


         <div className="row footer__top-row">
            <div className="col-12 md-col-6">
               <div className="footer__item footer__item_fst">
                  <div className="footer__item-tit">
                     Над проектом работали
            </div>
                  Автор: Дарья Донина<br />
                  Выпускающий редактор: Кристина Недкова<br />
                  Редакторы: Ильдар Валиуллин, Мария Леонтьева<br />
                  Дизайнер: Екатерина Седогина<br />
                  Иллюстратор: Марина Катина<br />
                  Бильд-редакторы: Илона Грибовская, Олеся Мельникова<br />
                  Редактор подкастов: Маргарита Девяткина<br />
                  Звукорежиссёр: Владимир Стеклов<br />
                  Продюсер: Николай Варт<br />
                  Разработчики: Lucky Site (Виталий Жуков, Иван Евсеев, Алексей Чудин)
         </div>
            </div>
            <div className="col-12 md-col-6">
               <div className="footer__item">
                  ТАСС выражает благодарность за содействие в подготовке спецпроекта первому проректору НИУ ВШЭ проф. Леониду
                  Гохбергу и научным сотрудникам ИСИЭЗ НИУ ВШЭ: Константину Вишневскому, Юлии Мильшиной, Анастасии Назаренко,
                  Лилиане Проскуряковой, Александру Соколову, Константину Фурсову, Александру Чулоку, Наталье Шматко, а также
                  руководителю отдела научных коммуникаций ИСИЭЗ Елене Гутарук.
         </div>
               <div className="footer__item sm-x-hidden mt-13">
                  <div className="footer__item-tit">
                     В материале использованы фотографии:
            </div>
                  Shutterstock/FOTODOM
         </div>

               <div className="footer__item sm-x-hidden mt-13">
                  <div className="footer__item-tit">
                     В подкастах использована <a href="https://filmmusic.io" target="_blank">музыка</a>:
            </div>
            <a href="https://filmmusic.io/song/3154-synthie-intro/" target="_blank">"Synthie Intro" by Sascha Ende</a>, <a href="http://creativecommons.org/licenses/by/4.0/" target="_blank">Licence: CC BY</a><br />
            <a href="https://filmmusic.io/song/5179-future-logo-intro/" target="_blank">"Future Logo Intro" by WinnieTheMoog</a>, <a href="http://creativecommons.org/licenses/by/4.0/" target="_blank">Licence: CC BY</a>
         </div>


            </div>
            <div className="col-12 md-hidden">
               <div className="footer__item">
                  В материале использованы фотографии: Shutterstock/FOTODOM
         </div>
            </div>
            <div className="col-12 md-hidden">
               <div className="footer__item mt-13">
                  В подкастах использована <a href="https://filmmusic.io" target="_blank">музыка</a>:<br />
            <a href="https://filmmusic.io/song/3154-synthie-intro/" target="_blank">"Synthie Intro" by Sascha Ende</a>, <a href="http://creativecommons.org/licenses/by/4.0/" target="_blank">Licence: CC BY</a><br />
            <a href="https://filmmusic.io/song/5179-future-logo-intro/" target="_blank">"Future Logo Intro" by WinnieTheMoog</a>, <a href="http://creativecommons.org/licenses/by/4.0/" target="_blank">Licence: CC BY</a>
         </div>
            </div>
         </div>

         <div className="row row_nw footer__bot-row">
            <div className="col">
               <a href="https://tass.ru/" target="_blank" className="footer__logo">
                  <img src={logo} alt="logo" />
               </a>
            </div>
            <div className="col col_grow">
               <div className="footer__txt">
                  &copy; ТАСС, 2019<br /> ТАСС информационное агентство (св-во о регистрации СМИ № 03247 выдано 02 апреля 1999
                  г. Государственным комитетом Российской Федерации по печати). Отдельные публикации могут содержать
                  информацию, не предназначенную для пользователей до 16 лет.
         </div>
            </div>
         </div>

         <button className="js-tgl-off close-btn" data-target-elements="#footer"><svg x="0px" y="0px" width="15px"
            height="15px" viewBox="16.5 16.5 15 15">
            <path className="close-btn-svg"
               d="M26.121,24l4.939-4.939c0.586-0.585,0.586-1.536,0-2.121c-0.586-0.586-1.535-0.586-2.121,0L24,21.879 l-4.939-4.939c-0.586-0.586-1.535-0.586-2.121,0c-0.586,0.585-0.586,1.536,0,2.121L21.879,24l-4.939,4.939 c-0.586,0.586-0.586,1.535,0,2.121C17.232,31.354,17.616,31.5,18,31.5s0.768-0.146,1.061-0.439L24,26.121l4.939,4.939 C29.232,31.354,29.616,31.5,30,31.5s0.768-0.146,1.061-0.439c0.586-0.586,0.586-1.535,0-2.121L26.121,24z" />
         </svg></button>
      </footer>
   );
}

export default Footer;