import React from 'react';
import pix from '../images/1.png';
import DLink from './DLink';
import Share from './Share';

const CartItem = (p) => {
   return (
      <div className="cart__item row row_col-middle">
         <div className="col">
            <div className={'cart__item-img ct-' + p.color}>
               <img src={pix} data-images={'9999->' + p.avatar5 + ',1000->' + p.avatar6} className="js-flex-img" alt="unit" />
            </div>
         </div>
         <div className="col col_grow">
            <div className="cart__item-tit">
               {p.title}
               <button className="close-btn" onClick={() => { p.removeFromCart(p.id) }}><svg x="0px" y="0px" width="15px" height="15px" viewBox="16.5 16.5 15 15"> <path className="close-btn-svg" d="M26.121,24l4.939-4.939c0.586-0.585,0.586-1.536,0-2.121c-0.586-0.586-1.535-0.586-2.121,0L24,21.879 l-4.939-4.939c-0.586-0.586-1.535-0.586-2.121,0c-0.586,0.585-0.586,1.536,0,2.121L21.879,24l-4.939,4.939 c-0.586,0.586-0.586,1.535,0,2.121C17.232,31.354,17.616,31.5,18,31.5s0.768-0.146,1.061-0.439L24,26.121l4.939,4.939 C29.232,31.354,29.616,31.5,30,31.5s0.768-0.146,1.061-0.439c0.586-0.586,0.586-1.535,0-2.121L26.121,24z" /> </svg></button>
            </div>
         </div>
      </div>
   );
}

let orderImgRef = React.createRef();

class Cart extends React.Component {
   constructor(props) {
      super(props);

      this.props.getCart();
   }

   componentDidMount() {
      window.$('#header').animate({ opacity: 1 }, 500);
      window.FlexImg('.js-flex-img');

      document.title = 'Корзина трендов';
      document.querySelector('meta[name="description"]').setAttribute('content', '');

      if (window.innerWidth > 1200) {
         window.stickyBlock('order-inner', true);
      }
   }

   getSnapshotBeforeUpdate() {
      return null;
   }

   componentDidUpdate() {
      window.$('.fadeInScreen, #header').animate({ opacity: 1 }, 500);
      window.FlexImg('.js-flex-img');

      if (window.innerWidth > 1200) {
         window.stickyBlock('order-inner', true);
      }

      // order img
      const imgRef = orderImgRef.current;

      if (imgRef.getAttribute('data-src') !== null && imgRef.getAttribute('data-src') !== 'null') {
         console.log(imgRef.getAttribute('data-src'));
         const img = new Image();

         imgRef.innerHTML = '';

         imgRef.classList.add('order__img_loading');

         img.src = imgRef.getAttribute('data-src');

         img.addEventListener('load', function () {
            imgRef.innerHTML = '';
            imgRef.classList.remove('order__img_loading');
            imgRef.appendChild(img);
         });
      }

   }

   render() {
      const items = (this.props.cart.length) ? this.props.cart.map((p) => <CartItem color={p.color} avatar5={p.avatar5} avatar6={p.avatar6} title={p.title} removeFromCart={this.props.removeFromCart} id={p.id} key={p.id} />) : '';

      return (
         <main className="main fadeInScreen fadeOutScreen">
            <div className="cart">
               <div className="cart__top">
                  <div className="cart__tit"> Возьму с собой в 2020 </div>
               </div>

               {items}

               {this.props.cart.length &&
                  <div className="cart__bot">
                     <button className="btn cart__bot-btn js-toggle js-document-toggle-off" data-target-elements="#order" onClick={this.props.doOrder}>оформить заказ</button>
                  </div>
               }

               {!this.props.cart.length &&
                  <React.Fragment>
                     <div className="cart__empty">
                        <p>Вы пока не выбрали ни одного явления, характерного для уходящего десятилетия.</p>
                        <p>С таким набором в будущее возьмут не всех.</p>
                        <p>Советуем <DLink to='/trends' history={this.props.route.history}>вернуться</DLink> к рассмотрению каталога.</p>
                     </div>

                     <Share />

                  </React.Fragment>
               }

            </div>

            <div id="order" className="order">
               <div>
                  <div id="order-inner">
                     <div className="order__tit">
                        Возьму с собой в 2020
               </div>
                     <div className="order__txt" dangerouslySetInnerHTML={{ __html: (this.props.share) ? this.props.share.title : 'Возможно, это просто одно из последствий индивидуализации: люди, заменившие живое общение онлайном, тоскуют по осознанной групповой деятельности и совместному творчеству. Частично эту потребность в соучастии закрывает краудсорсинг – то есть, буквально, предоставление ресурсов толпой. Благотворительность, поиск пропавших людей, сбор денег на фильм – все это способы почувствовать свою причастность к чему-то большему.' }}>
                     </div>

                     <div ref={orderImgRef} className="order__img" data-src={(this.props.share) ? this.props.share.image_url : null}> </div>

                     <ul className="sc order__sc">
                        <li className="sc__item">
                           <button className="f-btn f-btn_wh js-share-btn" data-network="fb" data-share-url={(this.props.share) ? this.props.share.share_url : ''}></button>
                        </li>
                        <li className="sc__item">
                           <button className="t-btn t-btn_wh js-share-btn" data-network="tw" data-share-url="https://10let.tass.ru" data-share-tit={(this.props.share) ? this.props.share.share_title + ' — ' : ''}></button>
                        </li>
                        <li className="sc__item">
                           <button className="v-btn v-btn_wh js-share-btn" data-network="vk" data-share-url={(this.props.share) ? this.props.share.share_url : ''}></button>
                        </li>
                        <li className="sc__item">
                           <button className="o-btn o-btn_wh js-share-btn" data-network="ok" data-share-url={(this.props.share) ? this.props.share.share_url : ''}></button>
                        </li>
                     </ul>
                  </div>

               </div>
            </div>

            <div className="order-empty">
               <div className="order-empty__tit">
                  Возьму с собой в 2020
            </div>
               <div className="order-empty__txt">
                  Оформи заказ, чтобы разблокировать результат
            </div>
            </div>

         </main>
      );
   }
}

export default Cart;