import React from 'react';
import Share from './Share';
import DLink from './DLink';

const Expert = (p) => {
	return (
		<div className={(p.txt) ? 'exp__item' : 'exp__item exp__item_s'}>
			<div className="exp__name">
				{p.name}
			</div>
			<div className="exp__pos">
				{p.pos}
			</div>
			<div className="exp__txt">
				{p.txt}
			</div>
		</div>
	);
}

const TrendMob = (p) => {
	return (
		<div className="trend-sl__item">
			<div className={'trend ' + p.color}>
				<div className="trend__img">
					<img src={p.prp.avatar2} alt="img" />
				</div>
				<div className="trend__tit">
					{p.prp.title}
				</div>
				<div className="trend__side">
					тренд <span>{p.prp.num} / {p.count}</span>
				</div>
				<DLink to={'/trend/' + p.prp.slug} className="trend__a"></DLink>
				{p.prp.expert_file && <div className="trend__pc"> <div className="hp-icon"> подкаст </div> </div>}
			</div>
		</div>
	);
}

const TrendDesk = (p) => {
	return (
		<div className="col-6">
			<div className="d-trend">
				<div className="d-trend__tit">
					{p.prp.title}
				</div>
				<div className="d-trend__img">
					<img src={p.prp.avatar1} alt="trend" />
				</div>
				<DLink to={'/trend/' + p.prp.slug} className="d-trend__a"></DLink>
				<div className="d-trend__img d-trend__img_hov">
					<img src={p.prp.avatar5} alt="trend" />
				</div>
				{p.prp.expert_file && <div className="d-trend__pc hp-icon">подкаст</div>}
			</div>
		</div>
	);
}


class Category extends React.Component {
	constructor(props) {
		super(props);

		this.props.getCats(this.props.route.match.params.slug);
	}

	componentDidMount() {
		window.$('#header').animate({ opacity: 1 }, 500);
		window.initSlider();
		window.scrollTo(0, 0);

		if (window.innerWidth > 1200) {
			window.stickyBlock('microtrends', true);
		}
	}

	getSnapshotBeforeUpdate() {
		window.unmountSlider();
		return null;
	}

	componentDidUpdate() {
		window.$('.fadeInScreen').animate({ opacity: 1 }, 500, function () {
			window.$('.trends__title, .desc-trend-units').css({ transform: 'translate(0px,0px)' });

			if (window.innerWidth > 1200) {
				window.stickyBlock('microtrends', true);
			}
		});
		window.initSlider();
		window.scrollTo(0, 0);



		if (this.props.cat) {
			document.title = this.props.cat.title;
			document.querySelector('meta[name="description"]').setAttribute('content', this.props.cat.text_desk.split('.')[0].replace('<p>', ''));
		}
	}

	render() {
		const color = (this.props.cat) ? 'ct-' + this.props.cat.color : '';

		const expElems = (this.props.cat) ? this.props.cat.experts.map((p, i) => <Expert name={p.name} pos={p.position} txt={p.text} key={i} />) : '';

		let itCount;

		if (this.props.cat) {
			this.props.cat.items.sort((a, b) => a.num - b.num);

			itCount = this.props.cat.items.length;
		}

		const mobTrendElems = (this.props.cat && window.innerWidth < 1200) ? this.props.cat.items.map(p => <TrendMob prp={p} color={color} count={itCount} key={p.id} />) : '';

		const deskTrendElems = (this.props.cat && window.innerWidth > 1200) ? this.props.cat.items.map(p => <TrendDesk prp={p} key={p.id} />) : '';

		return (
			<main className="main fadeInScreen fadeOutScreenDelay">
				<div className="trends trends_sng">

					<div className="trends__dbg"></div>

					<div id="microtrends" className="trends__desk desc-trend-units">

						<div className="row">
							{deskTrendElems}
						</div>

					</div>

					<div className={'trends__title ' + color}>
						<div className="trends__title-num"> {(this.props.cat) ? this.props.cat.num : ''} / 8 </div>
						<span>{(this.props.cat) ? this.props.cat.title : ''}</span>
					</div>

					<div className={'trends__desc ' + color}>
						<div className="lg-hidden">
							{(this.props.cat) ? this.props.cat.text_mob : ''}
						</div>
						<div className="md-x-hidden" dangerouslySetInnerHTML={{ __html: (this.props.cat) ? this.props.cat.text_desk : '' }}>
						</div>
						<div className="trends__desc-bot"> <DLink to="/trends">2010/20 - соберись в будущее</DLink> </div>
					</div>

					<div id="trend-slider" className="trend-sl of-h">
						{mobTrendElems}
					</div>

					<div className="exp">
						<div className={'exp__tit ' + color}>что говорят эксперты</div>

						{expElems}
					</div>

					<Share />

				</div>
			</main>
		);
	}

}

export default Category;