import React from 'react';
import ReactDOM from 'react-dom';
import App from './App';
import * as serviceWorker from './serviceWorker';
import { BrowserRouter } from 'react-router-dom';
import './index.css';

const state = {
	cats: null,
	trends: null,
	cart: [],
	cartCount: 0,
	share: null,
	is404: false,
	catsSlugs: [],
	trendsSlugs: []
};

const temp = function () {
	window.$.get('https://tass.lucky-site.com/api/basket/', (res) => {
		if (!window.Storage.get('seskey')) { window.Storage.set('seskey', res.session); }
	});
}

const reRender = function() {
	renderTree();
}

const getCats = (slug) => {
	temp();

	if (!state.cats) {
		state.cats = {};
		state.trends = {};

		window.$.get('https://tass.lucky-site.com/api/categories/?format=json', (res) => {
			for (const cat of res.results) {
				state.cats[cat.slug.replace(/-/g, '_')] = cat;

				state.catsSlugs.push(cat.slug);
				
				for (const item of cat.items) {
					state.trends[item.slug.replace(/-/g, '_')] = item;

					state.trendsSlugs.push(item.slug);
				}
			}

			if (slug) {
				state.category = state.cats[slug.replace(/-/g, '_')];
			}

			renderTree();
		});
	} else {
		if (slug) {
			state.category = state.cats[slug.replace(/-/g, '_')];
		}

		renderTree();
	}
}

// const getTrends = () => {
// 	if (!state.trends) {
// 		state.trends = {};
// 		state.trendsSlugs = [];

// 		window.$.get('https://tass.lucky-site.com/api/items/?format=json', (res) => {
// 			for (const it of res.results) {
// 				state.trends[it.slug.replace(/-/g, '_')] = it;

// 				state.trendsSlugs.push(it.slug);
// 			}
// 			renderTree();
// 		});
// 	} else {
// 		renderTree();
// 	}
// }

const getCart = () => {
	window.$.get('https://tass.lucky-site.com/api/basket/?session=' + window.Storage.get('seskey'), (res) => {
		state.cart = res.results;
		state.cartCount = res.count;
		renderTree();
	});
}

const addToCart = (id) => {
	window.$.post('https://tass.lucky-site.com/api/basket/action/?session=' + window.Storage.get('seskey'), { do: 'add', item_id: id }, (res) => {
		getCart();
	});
}

const removeFromCart = (id) => {
	window.$.post('https://tass.lucky-site.com/api/basket/action/?session=' + window.Storage.get('seskey'), { do: 'remove', item_id: id }, (res) => {
		getCart();
	});
}

const doOrder = () => {
	window.$.post('https://tass.lucky-site.com/api/order/?session=' + window.Storage.get('seskey'), '', (res) => {
		state.share = res;
		renderTree();
	});
}

function renderTree() {
	ReactDOM.render((
		<BrowserRouter>
			<App
				state={state}
				getCats={getCats}
				getCart={getCart}
				addToCart={addToCart}
				removeFromCart={removeFromCart}
				doOrder={doOrder}
				reRender={reRender}
			/>
		</BrowserRouter>
	), document.getElementById('root'));
}

renderTree();


// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
