import React from 'react';
import './App.css';
import { Route } from 'react-router-dom';
import Header from './components/Header';
import Footer from './components/Footer';
import HomePage from './components/HomePage';
import Categories from './components/Categories';
import Category from './components/Category';
import Trend from './components/Trend';
import Cart from './components/Cart';
import P404 from './components/P404';

class App extends React.Component {
	constructor(props) {
		super(props);

		this.props.getCats();
		
		this.allowUrls = null;
	}

	componentDidUpdate() {
		window.scrollTo(0, 0);
	}

	render() {
		const baseUrls = [
			'$/trends$',
			'$/cart$'
		];

		if (this.props.state.trendsSlugs.length && this.props.state.catsSlugs.length) {
			this.allowUrls = baseUrls.concat(this.props.state.trendsSlugs.map(p => '$/trend/' + p + '$'));
			this.allowUrls = this.allowUrls.concat(this.props.state.catsSlugs.map(p => '$/trends/' + p + '$'));
		}

		if (!this.allowUrls) return null;

		if (
			window.location.pathname !== '/' &&
			this.allowUrls.indexOf('$' + window.location.pathname.replace(/\/$/, '') + '$') === -1
		) {
			return <P404 reRender={this.props.reRender} />
		}

		return (
			<React.Fragment>
				<Route render={(prp) => {
					return (
						<Header
							cart_count={this.props.state.cartCount}
							getCart={this.props.getCart}
							{...prp}
						/>
					);
				}} />

				<Route exact path='/' component={HomePage} />

				<Route exact path='/trends' render={() => {
					return (
						<Categories cats={this.props.state.cats} getCats={this.props.getCats} />
					);
				}} />

				<Route exact path='/trends/:slug' render={(prp) => {
					return (
						<Category
							cat={this.props.state.category}
							route={prp}
							getCats={this.props.getCats}
						/>
					);
				}} />

				<Route exact path='/trend/:slug' render={(prp) => {
					return (
						<Trend
							trends={this.props.state.trends}
							route={prp}
							getTrends={this.props.getTrends}
							addToCart={this.props.addToCart}
							cart={this.props.state.cart}
							getCats={this.props.getCats}
							cats={this.props.state.cats}
						/>
					);
				}} />

				<Route exact path='/cart' render={(prp) => {
					return (
						<Cart
							cart={this.props.state.cart}
							share={this.props.state.share}
							getCart={this.props.getCart}
							removeFromCart={this.props.removeFromCart}
							doOrder={this.props.doOrder}
							route={prp}
						/>
					);
				}} />

				<Route render={(prp) => {
					const pathname = prp.location.pathname;

					let cl;

					if (pathname === '/trends') {
						cl = 'footer_nofix';
					}

					return (
						<Footer cl={cl} route={prp} />
					);
				}} />

			</React.Fragment>
		);
	}
}

export default App;