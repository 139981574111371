import React from 'react';
import Share from './Share';
import DLink from './DLink';

class HomePage extends React.Component {
   componentDidMount() {
      window.$('.fadeInScreen, #header, #footer').animate({ opacity: 1 }, 700);
      window.scrollTo(0, 0);

      document.title = 'Добро пожаловать в интернет-магазин десятилетия';
      document.querySelector('meta[name="description"]').setAttribute('content', 'Если ваше детство, отрочество, юность или зрелость пришлись на десятые годы XXI века, вам, вероятно, любопытно будет оглянуться назад и вспомнить самые заметные явления эпохи. Биткойны, сервисы доставки, облачные хранилища, онлайн-флэшмобы настолько прочно вошли в нашу жизнь, что мы едва ли можем ответить на вопрос: а как раньше-то жили?');
   }

   render() {
      return (
         <main className="main fadeOutScreen">
            <div className="first-screen row row_md-col-middle home" style={{ height: window.innerHeight + 'px' }}>
               <div className="home__tit">
                  <span>Сжатые<br />десятые</span>
               </div>

               <DLink to="/trends" className="gn-btn">к каталогу</DLink>

               <div className="col-12">
                  <div className="home__cont">

                     <DLink to="/trends" className="home__bg"></DLink>

                     <div className="home__txt">
                        <p>
                        Если ваше детство, отрочество, юность или зрелость пришлись на десятые годы XXI века, вам, вероятно, любопытно будет оглянуться назад и вспомнить самые заметные явления эпохи. Биткойны, сервисы доставки, облачные хранилища, онлайн-флешмобы настолько прочно вошли в нашу жизнь, что мы едва ли можем ответить на вопрос: а как раньше-то жили? Но отказываться ни от чего из этого не придётся, достаточно взять тот или иной тренд с собой в будущее.
					</p>
                        <p>
                        ТАСС совместно с Институтом статистических исследований и экономики знаний НИУ ВШЭ упаковал самые характерные явления уходящего десятилетия в привычный всем формат интернет-магазина. Изучайте тренды, выбирайте те, что кажутся вам стоящими, складывайте их в корзину. В финале каждый покупатель, простите, читатель сможет поделиться в социальных сетях уникальным коллажем. В общем, если вы по какой-то причине проспали последние десять лет,<br /> «Сжатые десятые»&nbsp;—&nbsp;самый краткий и ёмкий путеводитель по уходящей эпохе.
					</p>
                     </div>
                  </div>
               </div>

               <DLink to="/trends" className="arrow-btn anim-arrow"></DLink>

               <button className="js-toggle link-btn home__about-btn js-document-toggle-off" data-target-elements="#footer">о проекте</button>
            </div>

            <div className="first-screen block-quote md-hidden" style={{ height: window.innerHeight + 'px' }}>
               <div className="block-quote__tit">
                  Супермаркет —<br />
                  настоящий<br />
                  современный<br />
                  рай; житейская<br />
                  борьба<br />
                  прекращается<br />
                  у его дверей.
		</div>
               <div className="block-quote__author">
                  Мишель Уэльбек
		</div>
               <div className="block-quote__pos">
                  французский писатель, автор сборника<br />
                  эссе &laquo;Мир как супермаркет&raquo;
		</div>
               <div className="block-quote__bg"></div>

               <button className="js-toggle link-btn block-quote__about-btn js-document-toggle-off" data-target-elements="#footer">о проекте</button>
            </div>

            <Share cl='sh-block_fix' />
         </main>
      );
   }
}

export default HomePage;